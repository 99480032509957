import { defineStore } from 'pinia'

export const usePlayerStore = defineStore('player', {
    state: () => ({
        currentSong: {
            name: '',
            artist: '',
            album: '',
            url: '',
            cover_art_url: '',
        },
        isPlaying: false,
        initialized: false,
        player: require('amplitudejs'),
    }),
    actions: {
        async start(songs: any) {
            this.player.init({
                songs: songs,
                debug: true,
                repeat: true,
                continue_next: true,
                callbacks: {
                    pause: () => {
                        this.isPlaying = false
                    },
                    play: () => {
                        this.isPlaying = true
                    },
                    song_change: () => {
                        this.isPlaying = true
                        this.currentSong = this.player.getActiveSongMetadata()
                        this.updateMediaSesion();
                    },
                },
            })
            this.initialized = true
            this.playOrPause()
            this.currentSong = this.player.getActiveSongMetadata()
            this.updateMediaSesion();
        },
        async setSong(song: any) {
            this.player.addSong({
                name: song?.name,
                artist: song?.artist?.name,
                album: 'Untitled',
                url: song?.audio + '/webm',
                cover_art_url: song?.cover_picture + '/md',
            })
        },
        async playNow(song: any) {
          
            
            if (!this.initialized) {
                return this.start([
                    {
                        name: song?.name,
                        artist: song?.artist?.name,
                        album: 'Untitled',
                        url: song?.audio + '/webm',
                        cover_art_url: song?.cover_picture + '/md',
                    },
                ])
            }

            this.setSong(song)
            const index = this.player.getSongs().length - 1
            this.player.playSongAtIndex(index)
            this.isPlaying = true
        },
        async playOrPause() {
            if (this.isPlaying) {
                this.player.pause()
            } else {
                this.player.play()
            }
        },

        async updateMediaSesion(){
            if ('mediaSession' in navigator) {
                navigator.mediaSession.metadata = new window.MediaMetadata({
                  title: this.currentSong?.name,
                  artist: this.currentSong?.artist,
                  album: this.currentSong?.album,
                  artwork: [
                    { src: this.currentSong?.cover_art_url ,   sizes: '512x512',   type: 'image/png' },
                  ]
                });
      
                navigator.mediaSession.setActionHandler('play', ()=>{
                    this.playOrPause()
                });
                navigator.mediaSession.setActionHandler('pause', ()=>{
                    this.playOrPause()
                });
                navigator.mediaSession.setActionHandler('stop', ()=>{
                    this.playOrPause()
                });
                navigator.mediaSession.setActionHandler('previoustrack', ()=>{
                    this.player.next()
                    this.currentSong = this.player.getActiveSongMetadata()
                    this.updateMediaSesion();
                });
                navigator.mediaSession.setActionHandler('nexttrack', ()=>{
                    this.player.prev()
                });
              }
        }
    },
})
