<template>
    <ion-page>
      <ion-content :fullscreen="true">
        <div class="container flex-center">
            <img src="/img/login.svg" class="ion-margin-vertical">
            
            <div class="row inputs ion-margin-vertical">
              <ion-item>
                <ion-label position="floating">Email</ion-label>
                <ion-input type="email" v-model="email"></ion-input>
              </ion-item>
              <ion-text color="danger">
                <small>{{errors?.email}}</small>
              </ion-text>

              <ion-item>
                <ion-label position="floating">Password</ion-label>
                <ion-input type="password" v-model="password"></ion-input>
              </ion-item>
              <ion-text color="danger">
                <small>{{errors?.password}}</small>
              </ion-text>
            </div>
            <ion-button shape="round" color="primary" class="ion-margin-vertical" @click="login()">Login</ion-button>
            <div >
              <ion-text class="ion-margin-top">
                No account? 🥺
              </ion-text>
              <ion-button shape="round" color="light" @click="$router.push('/register')" class="ion-margin-start ion-margin-bottom">
                Create an account
              </ion-button>
            </div>
        </div>
        <ion-toast
          :is-open="toaster"
          :color="toaster_color"          
          :message="toaster_message"
          @didDismiss="toaster=false"
          :duration="3000"
          animated
        ></ion-toast>
      </ion-content>
    </ion-page>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import { IonPage,IonContent,IonItem,IonLabel,IonInput,IonToast,IonText,IonButton } from '@ionic/vue';
  import { useUserStore } from '@/store';
 

  export default  defineComponent({
    name: 'LoginPage',
    components: { IonContent, IonPage,IonItem,IonLabel,IonInput,IonToast },
    data() {
      return {
        email: "",
        password: "",
        toaster: false,
        toaster_message: "",
        toaster_color: "",
        errors: {
          email: "",
          password: ""
        },
      }
    }, 
    methods: {
      toast( message = "" , color = 'light'){
          this.toaster_message = message;
          this.toaster = true;
          this.toaster_color = color;
      },
      login(){
            const user =  useUserStore();

            user.login(this.email,this.password).then((res)=>{
              this.toast("You've logged in successfully","success")
              localStorage.AUTH_TOKEN = res.data.token;
              this.$router.push('/');
            }).catch((err)=>{
              if(err.response?.status == 422){
                // Validation error
                this.toast(err.response.data.message,'danger')
                this.errors = err.response.data.errors;
              }else{
                // Server error
                this.toast("Something went wrong, please try again later!",'warning')
              }            
            });

           
      }
    },
  });
  </script>
  <style scoped>
    .container{
      padding:2rem;
    }
    .flex-center{
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    .row{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    ion-item{
      --padding-start: 0px;
    }
  </style>
  
  