<template>
    <ion-page>
        <!-- Header -->
        <ion-header>
            <ion-toolbar style="padding-top: 10px">
                <ion-searchbar
                    animated
                    v-model="searchQuery"
                    @ion-change="search()"
                    @ion-focus="$router.push('/tabs/search')"
                    placeholder="Search"
                ></ion-searchbar>
            </ion-toolbar>
        </ion-header>

        <ion-tabs>
            <ion-router-outlet></ion-router-outlet>

            <!-- Player -->
            <ion-tab-bar v-if="player.initialized">
                <ion-button fill="clear" @click="player.player.prev()">
                    <ion-icon :icon="playSkipBack"></ion-icon>
                </ion-button>
                <ion-button
                    v-if="player.isPlaying"
                    fill="clear"
                    @click="player.playOrPause()"
                >
                    <ion-icon :icon="pause"></ion-icon>
                </ion-button>
                <ion-button v-else fill="clear" @click="player.playOrPause()">
                    <ion-icon :icon="play"></ion-icon>
                </ion-button>
                <ion-button fill="clear" @click="player.player.next()">
                    <ion-icon :icon="playSkipForward"></ion-icon>
                </ion-button>
                <ion-button fill="clear" id="open-modal" expand="block">
                    <ion-icon :icon="list"></ion-icon>
                </ion-button>
                <PlayList />
            </ion-tab-bar>

            <!-- Tabs navigator -->
            <ion-tab-bar slot="bottom">
                <ion-tab-button tab="tab1" @click="$router.push('/tabs/tab1')">
                    <ion-icon :icon="homeSharp" />
                    <ion-label>Tab 1</ion-label>
                </ion-tab-button>

                <ion-tab-button
                    tab="tab2"
                    @click="$router.push('/tabs/player')"
                    v-if="player.initialized"
                >
                    <ion-icon :icon="musicalNote" />
                    <ion-label>Player</ion-label>
                </ion-tab-button>

                <ion-tab-button
                    tab="profile"
                    @click="$router.push('/tabs/profile')"
                >
                    <ion-icon :icon="person" />
                    <ion-label>Profile</ion-label>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ion-page>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonButton,
    IonHeader,
    IonToolbar,
    IonSearchbar,
} from '@ionic/vue'
import {
    person,
    homeSharp,
    play,
    playSkipBack,
    playSkipForward,
    pause,
    list,
    musicalNote,
} from 'ionicons/icons'
import { useRouter } from 'vue-router'
import { usePlayerStore } from '@/store/player'
import PlayList from '@/components/PlayList.vue'
import { useSearchStore } from '../store/search'

const router = useRouter()
const player = usePlayerStore()
const searchQuery = ref<string>('')

const searchStore = useSearchStore()

const search = () => {
    searchStore.search(searchQuery.value)
}
onMounted(() => {
    if (!localStorage.AUTH_TOKEN) {
        router.push('/login')
    }
})
</script>
<style>
ion-content {
    --padding-top: 50px;
}
</style>
