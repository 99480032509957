import { defineStore } from 'pinia'
import axios from 'axios'

/* axios HTTP config */
axios.defaults.baseURL = 'https://api.hymn.krd/api'
// axios.defaults.baseURL = 'http://localhost:8000/api'
axios.defaults.headers.post['Accept'] = 'application/json'
if (localStorage.AUTH_TOKEN) {
    axios.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.AUTH_TOKEN
}

export const useAxiosStore = defineStore('Axios', {
    state: () => ({
        Axios: axios,
    }),
})

export const useUserStore = defineStore('user', {
    state: () => ({
        data: Object(),
        token: null,
        profile_picture: '',
        cover_picture: '',
    }),
    actions: {
        login(email: string, password: string) {
            return axios
                .post('/login', {
                    email: email,
                    password: password,
                })
                .then((res) => {
                    this.token = res.data.token
                    axios.defaults.headers.common['Authorization'] =
                        'Bearer ' + this.token
                    return res
                })
        },
        register(
            email: string,
            username: string,
            name: string,
            password: string,
            password_confirmation: string
        ) {
            return axios.post('/register', {
                email: email,
                password: password,
                username: username,
                name: name,
                password_confirmation: password_confirmation,
            })
        },
        profile() {
            axios.get('/profile').then((res) => {
                this.data = res.data
                return this.data
            })
        },
        update() {
            return axios.post('/profile', this.data).then((res) => {
                const formData = new FormData()
                formData.append('profile_picture', this.profile_picture)
                formData.append('cover_picture', this.cover_picture)
                return {
                    res1: res,
                    res2: axios.post('/profile', formData),
                }
            })
        },
    },
})
