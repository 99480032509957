import { defineStore } from 'pinia'

import Axios from 'axios'
import { Song } from '@/types/songTypes'

const searchInitial: {
    searchedSongs?: Array<Song>
} = {
    searchedSongs: undefined,
}

export const useSearchStore = defineStore('search', {
    state: () => searchInitial,
    actions: {
        search(search: string) {
            Axios.get('/search', { params: { search } }).then((res) => {
                this.searchedSongs = res.data
            })
        },
    },
})
