<template>
    <ion-modal ref="modal" trigger="open-modal" :initial-breakpoint="0.25" :breakpoints="[0, 0.25, 0.5, 0.75]">
      <ion-content class="ion-padding">
        <ion-searchbar v-model="search" placeholder="Search"></ion-searchbar>
        <ion-list>
          <ion-item v-for="(song,i) in player.player.getSongs().filter((song:any)=>song.name?.toLowerCase()?.includes(search.toLowerCase()) || song?.artist?.toLowerCase()?.includes(search.toLowerCase()))" :key="i" @click="player.player.playSongAtIndex(i)">
            <ion-avatar slot="start">
              <ion-img :src="song.cover_art_url"></ion-img>
            </ion-avatar>
            <ion-label>
              <h2>{{ song.name }}</h2>
              <p>{{ song.artist }}</p>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-modal>
</template>

<script setup lang="ts">
import { usePlayerStore } from '@/store/player';
import { IonModal,IonContent,IonSearchbar,IonList,IonAvatar,IonItem,IonImg,IonLabel } from '@ionic/vue';
import { ref } from 'vue';
const modalRef = ref()
const search = ref('')
const player = usePlayerStore()

</script>
