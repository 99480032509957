<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="container flex-center">
                <img src="/img/register.svg" class="ion-margin-vertical" />

                <div class="row inputs ion-margin-vertical">
                    <ion-item>
                        <ion-label position="floating">Name</ion-label>
                        <ion-input type="text" v-model="name"></ion-input>
                    </ion-item>
                    <ion-text color="danger">
                        <small>{{ errors?.name }}</small>
                    </ion-text>

                    <ion-item>
                        <ion-label position="floating">@Username</ion-label>
                        <ion-input type="text" v-model="username"></ion-input>
                    </ion-item>
                    <ion-text color="danger">
                        <small>{{ errors?.username }}</small>
                    </ion-text>

                    <ion-item>
                        <ion-label position="floating">Email</ion-label>
                        <ion-input type="email" v-model="email"></ion-input>
                    </ion-item>
                    <ion-text color="danger">
                        <small>{{ errors?.email }}</small>
                    </ion-text>

                    <ion-item>
                        <ion-label position="floating">Password</ion-label>
                        <ion-input
                            type="password"
                            v-model="password"
                        ></ion-input>
                    </ion-item>
                    <ion-text color="danger">
                        <small>{{ errors?.password }}</small>
                    </ion-text>

                    <ion-item>
                        <ion-label position="floating"
                            >Confirm your password</ion-label
                        >
                        <ion-input
                            type="password"
                            v-model="password_confirmation"
                        ></ion-input>
                    </ion-item>
                    <ion-text color="danger">
                        <small>{{ errors?.password }}</small>
                    </ion-text>
                </div>

                <ion-button
                    shape="round"
                    color="primary"
                    class="ion-margin-top"
                    @click="register()"
                    >Regiser</ion-button
                >
            </div>
            <ion-toast
                :is-open="toaster"
                :color="toaster_color"
                :message="toaster_message"
                @didDismiss="toaster = false"
                :duration="3000"
                animated
            ></ion-toast>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
    IonPage,
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonToast,
} from '@ionic/vue'
import { useUserStore } from '@/store'

export default defineComponent({
    name: 'RegisterPage',
    components: { IonContent, IonPage, IonItem, IonLabel, IonInput, IonToast },
    data() {
        return {
            email: '',
            username: '',
            name: '',
            password_confirmation: '',
            password: '',
            toaster: false,
            toaster_message: '',
            toaster_color: '',
            errors: {
                email: '',
                username: '',
                name: '',
                password: '',
                password_confirmation: '',
            },
        }
    },
    methods: {
        toast(message = '', color = 'light') {
            this.toaster_message = message
            this.toaster = true
            this.toaster_color = color
        },
        register() {
            const user = useUserStore()
            user.register(
                this.email,
                this.username,
                this.name,
                this.password,
                this.password_confirmation
            )
                .then((res) => {
                    this.toast("You've been registered successfully", 'success')
                    localStorage.AUTH_TOKEN = res.data.token
                    this.$router.push('/')
                })
                .catch((err) => {
                    if (err.response?.status == 422) {
                        // Validation error
                        this.toast(err.response.data.message, 'danger')
                        this.errors = err.response.data.errors
                    } else {
                        // Server error
                        this.toast(
                            'Something went wrong, please try again later!',
                            'warning'
                        )
                    }
                })
        },
    },
})
</script>

<style scoped>
.container {
    padding: 2rem;
}
.flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.row {
    display: flex;
    flex-direction: column;
    width: 100%;
}
ion-item {
    --padding-start: 0px;
}
</style>
