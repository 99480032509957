import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import TabsPage from '../views/TabsPage.vue'
import LoginPage from '../views/LoginPage.vue'
import RegisterPage from '../views/RegisterPage.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/tabs/tab1',
    },
    {
        path: '/tabs/',
        component: TabsPage,
        children: [
            {
                path: '',
                redirect: '/tabs/tab1',
            },
            {
                path: 'tab1',
                component: () => import('@/views/Tab1Page.vue'),
            },
            {
                path: 'tab2',
                component: () => import('@/views/Tab2Page.vue'),
            },
            {
                path: 'profile',
                component: () => import('@/views/ProfileView.vue'),
            },
            {
                path: 'profile/edit',
                component: () => import('@/views/ProfileEdit.vue'),
            },
            {
                path: 'songs/create',
                component: () => import('@/views/SongsCreate.vue'),
            },
            {
                path: 'songs/:id',
                component: () => import('@/views/SongView.vue'),
            },
            {
                path: 'player',
                component: () => import('@/views/PlayerPage.vue'),
            },
            {
                path: 'search',
                component: () => import('@/views/SearchTab.vue'),
            },
        ],
    },
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/register',
        component: RegisterPage,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
